





















import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Prop } from "vue-property-decorator";

import { Seminar } from "@/core/models";

@Component
export default class SeminarList extends Vue {
  @Prop({ default: () => false }) admin!: boolean;
  @Getter("profile/getUserEmail") myEmail!: string;
  @Getter("seminars/loading") loading!: boolean;
  @Getter("seminars/retrieved") retrieved!: boolean;
  @Getter("seminars/seminars") seminars!: Seminar[];
  @Action("seminars/getAll") getAllSeminars!: () => Promise<void>;

  get list() {
    if (!this.admin) return this.seminars;
    return this.seminars.filter(x => x.owner?.email === this.myEmail);
  }
  goToSeminar(s: Seminar) {
    if (this.admin) this.$router.push(`/seminars/edit/${s.id}`);
    else this.$router.push(`/seminars/dashboard/${s.id}`);
  }
  created() {
    if (!this.retrieved) this.getAllSeminars();
  }
}
